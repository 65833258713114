import { AuthAction, AuthState, SET_USER, SET_LOADING, SET_LOGOUT, SET_ERROR, SET_SUCCESS } from '../types';

const initialState: AuthState = {
    user: null,
    authenticated: false,
    loading: false,
    error: '',
    success: ''
}

export default (state = initialState, action: AuthAction) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                authenticated: true
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_LOGOUT:
            return {
                ...state,
                user: null,
                authenticated: false,
                loading: false
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}